.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-top: 2em;
}
.image {
  width: 38%;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  transform: translateX(70.5px);
}
.btnBox {
  transform: translateX(70.5px);
}
.spacer {
  width: 1em;
}
.actionInterface {
  width: 30%;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .image {
    width: 45%;
  }
}
@media screen and (max-width: 767px) {
  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1em;
  }
  .image {
    width: 100%;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
    border-radius: 0px;
    transform: translateX(0px);
  }
  .btnBox {
    transform: translateX(0px);
    margin-top: 10px;
  }
}
