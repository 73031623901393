.flexBox {
  width: 100%;
  display: flex;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.passBox {
  width: 100%;
}

.pass {
  width: 300px;
  /* box-shadow: 0px 1px 5px 0px rgba(0,0,0,.2); */
  /* border: 1px solid var(--specialGrey); */
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
  border: 1px solid rgb(226, 224, 224);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 45px;
  padding-right: 45px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.logoBox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo {
  height: 70px;
}

.title {
  text-align: center;
  margin-top: 0px;
  font-size: 18px;
  font-weight: 500;
}

.subtitle {
  text-align: center;
  margin-top: 5px;
  font-size: 13px;
  font-weight: 500;
}

.rentalAddressBox {
  box-sizing: border-box;
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 10px;
  margin-top: 10px;
  padding: 0px 7px;
  padding-top: 8px;
  padding-bottom: 5px;
  word-wrap: break-word;
}

.validFromBox {
  margin-top: 8px;
  padding-left: 7px;
  padding-bottom: 5px;
  border-bottom: 1px dashed #c4c4c4;
}

.validFrom {
  font-size: 14px;
  font-weight: 400;
  margin-right: 5px;
  margin-bottom: 5px;
}

.validFromItems {
  font-weight: 700;
  color: black;
  font-size: 14px;
}

.validToBox {
  margin-top: 8px;
  padding-left: 7px;
  padding-bottom: 5px;
}

.infoBox {
  box-sizing: border-box;
  width: 100%;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 130%;
  color: var(--darkGrey);
}

.link {
  width: 100%;
  color: blue;
  font-weight: 500;
  text-align: justify;
  text-decoration: underline;
}

.footerTitle {
  font-size: 13px;
  text-align: center;
  margin-top: 10px;
}

.passForm {
  width: 38%;
  margin-left: 2%;
  margin-top: 40px;
}

.boxTop {
  width: 80%;
  display: flex;
  justify-content: center;
}

.qrCode {
  width: 100%;
}

.timeBox {
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 10px;
  margin-top: 10px;
}

.passImg {
  width: 350px;
  height: 653.33px;
}

.passImgBox {
  width: 350px;
  height: 653.33px;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
  border: 1px solid rgb(226, 224, 224);
  margin-top: 20px;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 10px;
}

.justifyCenter {
  text-align: justify;
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1199px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {}

@media screen and (max-width: 767px) {
  .title {
    font-size: 18px;
  }

  .subtitle {
    text-align: center;
    margin-top: 5px;
    font-size: 13px;
    font-weight: 500;
  }

  .container {
    width: 100%;
    padding: 0px;
  }

  .pass {
    box-sizing: border-box;
    max-width: auto;
    width: 100%;
    padding-top: 11px;
    padding-bottom: 14px;
    padding-left: 0px;
    padding-right: 0px;
    border-radius: 0px;
    margin: 0;
    margin-bottom: 10px;
  }

  .qrCode {
    width: 100%;
  }

  .logo {
    height: 60px;
  }

  .infoBox {
    box-sizing: border-box;
    font-size: 14px;
    padding: 0px 5px;
  }
}