.subHeader {
  display: flex;
  align-items: center;
  height: 103px;
  border-radius: 0 20px 0 0;
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 45px;
  margin-right: 17px;
}
.title {
  font-size: 35px;
  font-weight: 500;
}
.form {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 94px;
  padding-bottom: 108px;
}
.innerForm {
  width: 56%;
}
.inputLong {
  margin-bottom: 25px;
}
.saveBtn {
  width: 40%;
}
.btnBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 150px;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .subHeader {
    border-radius: 0;
  }
  .innerForm {
    width: 80%;
  }
  .saveBtn {
    width: 200px;
  }
}
@media screen and (max-width: 767px) {
  .circle {
    width: 16px;
    height: 16px;
    margin-left: 20px;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
  }
  .subHeader {
    border-radius: 0;
  }
  .form {
    width: 100%;
    margin-top: 30px;
  }
  .saveBtn {
    width: 200px;
  }
  .inputLong {
    margin-bottom: 18px;
  }
  .innerForm {
    width: 80%;
  }
}
