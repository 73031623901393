.title {
    font-size: 18px;
    font-weight: 500;
    margin: 5px;
}

.text {
    font-size: 14px;
    font-weight: 500;
}

.icon svg {
    height: 50px;
    fill: var(--specialGrey);
}

.iconActive svg {
    height: 50px;
    fill: var(--thirdColor);
}

[data-icon="ReviewInfo"] svg {
    fill: none;
}

[data-icon="ReviewInfo"] svg path {
    stroke: var(--specialGrey);
}

.iconActive[data-icon="ReviewInfo"] svg {
    fill: none;
}

.iconSucess[data-icon="ReviewInfo"] svg path {
    stroke: var(--sucessColor);
}

.iconSucess[data-icon="ReviewInfo"] svg {
    fill: none;
}

.iconActive[data-icon="ReviewInfo"] svg path {
    stroke: var(--thirdColor);
}

.iconSucess svg {
    height: 50px;
    fill: var(--sucessColor);
}

.lineInvisible {
    width: 89px;
    height: 7px;
    visibility: hidden;
    margin-top: 15px;
}

.line {
    width: 89px;
    height: 7px;
    background-color: var(--orange);
    margin: 0 auto;
    margin-top: 15px;
    border-radius: 13px;
}

[data-completed = "textColorSucess"] + span{
    background-color: var(--sucessColor);
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1199px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .icon svg {
        height: 50px;
    }

    .iconActive svg {
        height: 50px;
    }

    .iconSucess svg {
        height: 50px;
    }

    .title {
        font-size: 20px;
    }

    .text {
        font-size: 14px;
        font-weight: 500;
    }
}

@media screen and (max-width: 767px) {
    .icon svg {
        height: 30px;
    }

    .iconActive svg {
        height: 30px;
    }

    .iconSucess svg {
        height: 30px;
    }

    .title {
        font-size: 14px;
    }

    .text {
        font-size: 12px;
        font-weight: 500;
        height: 20px;
        max-width: 100px;
    }

    .lineInvisible {
        width: 50px;
        height: 5px;
        visibility: hidden;
        margin-top: 20px;
    }

    .line {
        width: 50px;
        height: 5px;
        background-color: var(--thirdColor);
        margin: 0 auto;
        margin-top: 20px;
        border-radius: 13px;
    }
}