.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
  /* margin-top:200px ; */
}
.box {
  width: 90%;
  margin-top: 200px;
  border-radius: 20px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
}
.box__flexBox {
  /* position: relative; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 20px 20px;
  padding-top: 20px;
  padding-bottom: 108px;
}
.box__subHeader {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 20px 20px 0 0;
  height: 103px;
}
.box__circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 45px;
  margin-right: 17px;
}
.box__title {
  font-size: 35px;
  font-weight: 500;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .box {
    width: 98%;
    margin-top: 230px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .box {
    width: 100%;
    margin-top: 230px;
    border-radius: 0px;
  }
  .box__subHeader {
    border-radius: 0;
    height: 103px;
  }
}
@media screen and (max-width: 767px) {
  .box {
    width: 100%;
    margin-top: 44px;
    border-radius: 0px;
  }
  .box__subHeader {
    border-radius: 0;
    height: 103px;
  }
  .box__circle {
    width: 16px;
    height: 16px;
    margin-left: 20px;
  }
  .box__title {
    font-size: 20px;
    font-weight: 500;
  }
}
