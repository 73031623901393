.title {
    font-size: 18px;
    font-weight: 500;
}

.box {
    margin-bottom: 24px;
}

.titleBox {
    margin-top: 5px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.priceBox {
    border-top: 2px solid var(--specialGrey);
    padding-top: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tHead {
    font-size: 14px;
    font-weight: 500;
    width: 50%;
    min-width: 100px;
}

.tSubTotal {
    font-size: 16px;
    font-weight: 500;
    width: 54%;
    min-width: 100px;
}

.textDecoration {
    text-decoration: line-through;
}

.tableBox {
    width: 100%;
}

.table {
    width: 100%;
}

.trBox {
    width: 100%;
    text-align: left;
    display: flex;
    ;
}

.vehicleInfoBox {
    margin-top: 20px;
}

.tHeadSpace {
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    min-width: 100px;
    padding-top: 10px;
}

.editBtn button {
    width: 39px;
    height: 39px;
    border-radius: 1000px;
    border: 2px solid #fff;
    font-size: 24px;
    cursor: pointer;
    outline-style: none;
    background-color: var(--thirdColor);
}

.circleImg {
    margin-top: 0px;
    width: 16px;
    height: 16px;
}

.addonBox {
    margin-top: 20px;
}

.addonPrice {
    text-align: right;
}

@media screen and (max-width:767px) {
    .title {
        font-size: 16px;
    }
}