.container {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
}
.upperBox {
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  width: 100%;
}
.titleBox {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: rgb(236, 236, 236);
}
.titleBox h1 {
  font-size: 18px;
}
.tableBox {
  margin: 20px;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 10px;
  box-sizing: border-box;
  width: 100%;
}
.btnDownload button {
  width: 300px;
}
.table {
  margin-top: 20px;
  min-width: 1200px;
}
.table thead tr th {
  border: 1px solid rgb(236, 236, 236);
  padding: 10px;
  font-size: 14px;
}
.firstTr {
  background-color: rgb(243, 243, 243);
}
.table tbody tr td {
  border: 1px solid rgb(236, 236, 236);
  padding: 10px;
  font-size: 14px;
}
.uploadActionBox {
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.importBox {
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
}
.importBox label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 160px;
}
.csv {
  margin-right: 100px;
}
.chooseBtn {
  position: absolute;
  top: -8px;
  padding: 10px 12px;
  border: 1px solid rgb(131, 131, 131);
  border-radius: 10px;
  cursor: pointer;
  margin-left: 10px;
  background-color: #fff;
  left: 100px;
  font-weight: 500;
  z-index: 2;
}
.cover {
  position: absolute;
  height: 30px;
  width: 200px;
  background-color: #fff;
  left: 74px;
  z-index: 1;
}
.acceptBtn {
  margin-top: 20px;
}
.scrollBox {
  overflow: auto;
  width: 100%;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .table {
    min-width: 1200px;
  }
  .csv {
    margin-right: 10px;
  }
  .importBox {
    width: 100%;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
  }
  .importBox label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 10px;
  }
  /* .chooseBtn {
      display: none;
    } */
  .uploadBox {
    position: relative;
  }
  .chooseBtn {
    position: absolute;
    padding: 0 12px;
    height: 25px;
    border: 1px solid rgb(131, 131, 131);
    border-radius: 0px;
    cursor: pointer;
    margin-left: 10px;
    background-color: #fff;
    left: -10px;
    top: 8px;
    font-weight: 500;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cover {
    display: none;
  }
  .upload {
    border: 1px solid var(--lightGrey);
    margin-top: 8px;
  }
  .upload button {
    background-color: #fff !important;
  }
  .container {
    padding: 5px;
    box-sizing: border-box;
    width: 100%;
  }
}
