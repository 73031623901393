.infoFormBox {
  width: 100%;
}

.header {
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  margin-bottom: 10px;
}
.title {
  color: white;
  margin-right: 5px;
}
.dropDown {
  background-color: transparent;
  border: none;
  outline-style: none;
  cursor: pointer;
  transform: rotate(90deg) scaleY(1.8);
  font-size: 16px;
  font-weight: 500;
  color: white;
}
.btnRemoveText {
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.btnDown {
  margin-left: 16px;
  margin-right: 10px;
}
.btnRemove {
  font-size: 12px;
  position: absolute;
  right: 16px;
}
.inputBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.inputLong {
  width: 100%;
}
.inputLong p {
  color: var(--superDarkGrey);
}
.inputShort {
  width: 48%;
}
.copyTool {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--lightGrey);
  margin-bottom: 10px;
}
.copyToolText {
  font-size: 15px;
  font-weight: 700;
  color: var(--superDarkGrey);
  margin: 10px 0px;
}
.copyBtn {
  font-size: 14px;
  margin-right: 5px;
  background-color: var(--thirdColor);
  border: none;
  color: white;
  width: 50px;
  padding: 3px 0px;
  border-radius: 5px;
  cursor: pointer;
}
.pasteBtn {
  font-size: 14px;
  background-color: var(--mainColor);
  border: none;
  color: white;
  width: 50px;
  padding: 3px 0px;
  border-radius: 5px;
  cursor: pointer;
}
.textAlert {
  background-color: var(--lightGrey);
  color: var(--superDarkGrey);
  line-height: 150%;
  padding: 8px;
  margin-top: 4px;
  margin-bottom: 8px;
  font-size: 14px;
}
.copyToolBtnBox {
  display: flex;
  width: 100px;
}
.searchSelector label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
}
.searchSelector input {
  box-sizing: border-box;
  border-radius: 100px;
  border: 1px solid var(--specialGrey);
  height: 34px;
  overflow: hidden;
  width: 100%;
  outline-style: none;
  margin-top: 8px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 17px;
  text-indent: 4px;
  color: var(--mediumGrey);
}
.subtitle {
  background-color: var(--lightGrey);
  color: var(--superDarkGrey);
  line-height: 150%;
  padding: 8px;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
}

@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .searchSelector input {
    height: 28px;
    margin-top: 8px;
    font-size: 14px;
  }
}
