.box {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 10px;
}
.flexBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.recipientBox {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}
.paymentBox {
  box-sizing: border-box;
  width: 30%;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.paymentDetails {
  width: 80%;
  border: 2px solid var(--mediumGrey);
  padding: 20px 44px;
  margin-top: 20px;
  border-radius: 20px;
}
.paymentDetailItem {
  display: flex;
  flex-direction: column;
}
.passNum {
  font-size: 18px;
  color: var(--superDarkGrey);
  margin-bottom: 15px;
}
.table {
  width: 100%;
  padding-left: 1rem;
  margin-bottom: 30px;
}
.table thead tr th {
  text-align: left;
  color: var(--superDarkGrey);
  font-weight: 400;
  height: 20px;
  font-size: 14px;
}
.shortTh {
  width: 100px;
}
.table tbody .firstRow td {
  font-size: 14px;
  color: black;
  font-weight: 600;
  padding-bottom: 10px;
}
.secondRow th {
  border-top: 1px solid var(--lightGrey);
  text-align: left;
  padding-top: 10px;
  color: var(--superDarkGrey);
  font-weight: 500;
}
.secondRow td {
  border-top: 1px solid var(--lightGrey);
  text-align: right;
  padding-top: 10px;
  color: var(--superDarkGrey);
  font-weight: 500;
}
.totalBox {
  border: 2px solid var(--mediumGrey);
  border-radius: 12px;
  margin-top: 10px;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.totalBox h4 {
  font-size: 18px;
  color: var(--superDarkGrey);
}
.btnBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.btn {
  width: 254px;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .flexBox {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .paymentBox {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .paymentDetails {
    box-sizing: border-box;
    width: 60%;
    border: 2px solid var(--mediumGrey);
    padding: 20px 44px;
    margin-top: 20px;
    border-radius: 20px;
  }
  .recipientBox {
    margin-right: 0px;
  }
}

.container_option {
  margin-bottom: 20px;
}
.container_option .box__inputField label {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  overflow: visible;
  font-weight: 500;
  margin: 10px 0;
  cursor: pointer;

}

.container_option .box__inputField label span {
  order: 1;
  margin-top: 2px;
}

.container_option .box__inputField label span+div {
  margin-right: 3px;
  flex: 0 0 25px;
}

.container_option .box__inputField input {
  width: 20px;
}

.container_option .box__subTitle {
  font-size: 17px;
  margin-bottom: 12px;
  color:#2e3032
}

.container_option .box__btnLeftInnerBox {
  text-align: left;
}

.container_option .box__btnLeft {
  margin-top: 30px;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .flexBox {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .paymentBox {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .paymentDetails {
    box-sizing: border-box;
    width: 60%;
    border: 2px solid var(--mediumGrey);
    padding: 20px 44px;
    margin-top: 20px;
    border-radius: 20px;
  }
  .recipientBox {
    margin-right: 0px;
  }
  .btn {
    width: 200px;
  }
}
@media screen and (max-width: 767px) {
  .flexBox {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .paymentBox {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .recipientBox {
    margin-right: 0px;
  }
  .btn {
    width: 200px;
  }
}
