.infoBox {
  padding: 30px 49px 1px;
  border: 2px solid var(--darkGrey);
  border-radius: 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0px;
}
.summaryBox {
  width: 100%;
}
.title {
  font-size: 18px;
  font-weight: 500;
}
.box {
  margin-bottom: 54px;
}
.titleBox {
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container {
  padding-bottom: 20px;
  border-bottom: 2px solid var(--specialGrey);
}
.tHead {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  min-width: 100px;
  padding-top: 5px;
}

.tableBox {
  width: 100%;
}
.table {
  width: 100%;
  border-bottom: 2px solid var(--lightGrey);
  margin-bottom: 20px;
}
.table tbody td {
  padding-bottom: 10px;
}
.trBox {
  width: 100%;
  text-align: left;
}

.vehicleInfoBox {
  margin-top: 20px;
}
.tHeadSpace {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  min-width: 120px;
  padding-top: 5px;
}
.tableBelow {
  width: 100%;
  color: var(--darkGrey);
}
.tableBelow th {
  text-align: left;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
  padding: 3px 0px;
}
.tableBelow td {
  text-align: left;
  width: 50%;
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
}
.line {
  margin-bottom: 20px;
}
.editBtn button {
  width: 34px;
  height: 34px;
}
.totalBox {
  height: 50px;
  width: 100%;
  border: 2px solid var(--darkGrey);
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.total {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.textLeft {
  font-size: 20px;
  margin-left: 5%;
  font-weight: 500;
  line-height: 65px;
}
.textRight {
  font-size: 20px;
  margin-right: 5%;
  font-weight: 500;
  line-height: 65px;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .editBtn button {
    width: 35px;
    height: 35px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .infoBox {
    box-sizing: border-box;
    padding: 10px 10px;
    width: 98%;
  }
  .title {
    font-size: 16px;
  }
  .subtotal {
    font-size: 16px;
  }
  .totalBox {
    height: 40px;
  }
  .textLeft {
    font-size: 20px;
  }
  .textRight {
    font-size: 20px;
  }
}
