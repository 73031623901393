.box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
  width: 100%;
  padding: 0px 20px;
}

table tr:last-child td:last-child {
  border-radius: 0px 0px 10px 0px;
}
table tr:last-child td:first-child {
  border-radius: 0px 0px 0px 10px;
}
.tableHeader {
  width: 100%;
  height: 74px;
}
.tHeaderItem {
  font-size: 15px;
  font-weight: 500;
  color: white;
  text-align: left;
  padding: 0px 10px;
  height: 74px;
}
.boxRight {
  width: 70%;
}
.borderBox {
  width: 100%;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  /* overflow: auto; */
  margin-left: 0px;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
}
.table .tableHeader th:first-child {
  border-radius: 10px 0px 0px 0px;
  background-color: var(--mainColor);
}
.table .tableHeader th:last-child {
  border-radius: 0px 10px 0px 0px;
  background-color: var(--mainColor);
}
.table .tableHeader th {
  background-color: var(--mainColor);
}
.item {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 0px 10px;
}
.sheet {
  height: 73px;
}
.btnAction {
  text-align: center;
  width: 3% !important;
  min-width: 100px;
  border-bottom: 1px solid rgb(230, 230, 230);
}
.btnAction button {
  padding: 3px 5px;
  border: 1px solid #898e93;
  border-radius: 10px;
  cursor: pointer;
  outline-style: none;
}
.statusBox {
  padding: 5px 10px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  font-weight: 600;
  text-align: center;
}
.active {
  background-color: #15a021;
  color: white;
}
.readyToScan {
  background-color: #9ccc8b;
  color: white;
}
.incomplete {
  background-color: #c4c4c4;
  color: white;
}
.expired {
  background-color: #a01515;
  color: white;
}
.refunded {
  background-color: #e9a41e;
  color: white;
}
.incompleteRentalCar {
  background-color: var(--darkGrey);
  color: white;
}
.actionsBtnItems {
  position: absolute;
  top: 30px;
  left: -25px;
  z-index: 1;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--lightGrey);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
}
.actionsBtnItems a button {
  width: 120px;
  border-radius: 0;
  border: none;
  border-bottom: 1px dashed var(--lightGrey);
  background-color: #fff;
}
.actionsBtnItems a button:hover {
  background-color: var(--mainColor);
  color: white;
  transition: 0.4s;
}
.actionsBtnItems a:last-child button {
  border: none;
}
.guestInfoBox {
  width: 25%;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  overflow: hidden;
  box-sizing: border-box;
  padding-bottom: 40px;
}
.guestHeader {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  background-color: var(--mainColor);
  display: flex;
  align-items: center;
  padding-left: 30px;
  color: white;
  font-weight: 500;
  max-height: 300px;
}
.guestTable {
  margin-left: 20px;
  margin-top: 10px;
}
.guestTable th {
  text-align: left;
  color: var(--superDarkGrey);
  padding: 10px;
  font-weight: 400;
}
.guestTable td {
  font-weight: 500;
  padding: 10px;
}
.breakWord {
  word-wrap: break-word;
  word-break: break-all;
}
.checkboxTh {
  padding: 0px 10px;
}
.checkBoxTd {
  border-bottom: 1px solid rgb(230, 230, 230);
}
.input {
  background-color: #fff !important;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #d8d8d8;

  outline-style: none;
  cursor: pointer;
}
.input:checked {
  background-color: #fff;
  background: url('../../../../../assets/check.svg') no-repeat center;
}
.refundButtonContainer {
  box-sizing: border-box;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.refundButtonContainer .refundBtn {
  width: 20%;
}
.flexcol {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 0;
  }
  .boxRight {
    width: 98%;
  }
  .borderBox {
    width: 100%;
    border-radius: 0px 0px 0px 0px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    /* overflow: auto; */
    margin-left: 0px;
  }
  .guestInfoBox {
    width: 98%;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  .table .tableHeader th:first-child {
    border-radius: 0px 0px 0px 0px;
  }
  .table .tableHeader th:last-child {
    border-radius: 0px 0px 0px 0px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 0;
  }
  .table {
    min-width: 800px;
  }
  .boxRight {
    width: 100%;
  }
  .borderBox {
    width: 100%;
    border-radius: 0px 0px 0px 0px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    overflow: auto;
    margin-left: 0px;
  }
  .guestInfoBox {
    width: 100%;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  .table .tableHeader th:first-child {
    border-radius: 0px 0px 0px 0px;
  }
  .table .tableHeader th:last-child {
    border-radius: 0px 0px 0px 0px;
  }
  .refundButtonContainer {
    box-sizing: border-box;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    padding-left: 20px;
  }
  .refundBtn {
    margin-bottom: 14px;
  }
}
@media screen and (max-width: 767px) {
  .table {
    min-width: 1000px;
  }
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 0;
  }
  .boxRight {
    width: 100%;
  }
  .borderBox {
    width: 100%;
    border-radius: 0px 0px 0px 0px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    overflow: auto;
    margin-left: 0px;
  }
  .guestInfoBox {
    width: 100%;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0px;
    overflow: auto;
    margin-bottom: 20px;
  }
  .guestTable th {
    font-size: 14px;
  }
  .guestTable td {
    font-size: 14px;
  }
  .table .tableHeader th:first-child {
    border-radius: 0px 0px 0px 0px;
  }
  .table .tableHeader th:last-child {
    border-radius: 0px 0px 0px 0px;
  }
  .refundButtonContainer {
    box-sizing: border-box;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    padding-left: 20px;
  }
  .refundBtn {
    margin-bottom: 14px;
  }
}
