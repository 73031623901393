.box {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.submitBtn {
  text-align: center;
  margin-top: 40px;
  width: 254px;
}
.formBox {
  width: 45%;
  box-sizing: border-box;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px;
}
.warning {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}
.instruction {
  text-align: center;
  max-width: 600px;
  line-height: 150%;
  font-size: 18px;
  margin-bottom: 18px;
  font-weight: 700;
}
.btn {
  width: 254px;
  margin: 0 40px;
  margin-bottom: 8px;
}
.alertPic svg {
  width: 54px;
  height: 54px;
}
.flex {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .formBox {
    width: 60%;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .btn {
    width: 200px;
    margin-bottom: 8px;
  }
  .formBox {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .container {
    padding: 10px 10px;
  }
  .warning {
    font-size: 20px;
  }
  .instruction {
    font-size: 16px;
  }
  .alertPic svg {
    width: 44px;
    height: 44px;
  }
  .btn {
    width: 200px;
    margin-bottom: 8px;
  }
  .formBox {
    padding: 0px 10px;
    width: 100%;
  }
  .flex {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}
