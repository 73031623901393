.box {
  box-sizing: border-box;
  margin-top: 40px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}
.header {
  width: 100%;
  height: 40px;
  background-color: var(--superDarkGrey);
  border-radius: 10px 10px 0px 0px;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
}
.flexBox {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.box__flexBox {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 20px 20px;
  padding-top: 20px;
  padding-bottom: 108px;
}

.box__tabBox {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  padding-left: 30px;
}

.flexBox input {
  margin-bottom: 8px;
}
.boxLeft {
  margin-right: 20px;
  width: 50%;
}
.boxRight {
  margin-left: 20px;
  width: 50%;
}
.shortInputBox {
  display: flex;
  justify-content: space-between;
}
.inputShort {
  width: 49%;
}
.searchInput {
  min-width: 120px;
  margin-right: 5px;
}
.searchInput input {
  display: flex;
  height: 23px;
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--lightGrey);
  outline-style: none;
  font-size: 14px;
}
.searchInput input:focus {
  border-bottom: 2px solid var(--mainColor);
}

.searchInput label {
  font-size: 17px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: var(--mediumGrey);
}
.rentalItem {
  margin-bottom: 20px;
}
.btnBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.gridContainer {
  width: 72vw;
}


.gridContainer .box__flexBox {
  position: relative;
  width: 100%;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 20px 20px;
  padding-top: 20px;
  padding-bottom: 108px;
}

.gridContainer .box__tabBox{
  padding-left: 0;
  margin-top: 20px;;
}

.formParent {
  display: grid;
  justify-content: center;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .flexBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
  }
  .boxLeft {
    margin-right: 0px;
    width: 100%;
    margin-bottom: 30px;
  }
  .boxRight {
    margin-left: 0px;
    width: 100%;
  }
  .searchInput label {
    font-size: 14px;
  }
}
