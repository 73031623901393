.container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 320px;
}

.box {
    border-radius: 20px;
    margin-top: 200px;
    min-width: 320px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
    width: 64%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn {
    width: 254px;
    margin-top: 20px;
}

.videoBox {
    width: 70%;
    border: 1px solid var(--lightGrey);
}

.videoContainer {
    position: relative;
    padding-bottom: 83%;
    height: 0;
    overflow: hidden;
}

.videoContainer iframe,
.videoContainer object,
.videoContainer embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .box {
        width: 80%;
        margin-top: 230px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .box {
        width: 100%;
        border-radius: 0px;
        margin-top: 230px;
        min-width: 320px;
    }

    .btn {
        width: 200px;
        margin-top: 116px;
        display: flex;
        justify-content: center;
    }

    .videoBox {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .box {
        box-sizing: border-box;
        width: 100%;
        border-radius: 0px;
        margin-top: 44px;
        min-width: 320px;
        padding: 10px;
    }

    .videoBox {
        width: 100%;
    }

    .btn {
        width: 200px;
        margin-top: 25px;
        display: flex;
        justify-content: center;
    }
}