.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}

.box {
  border-radius: 20px;
  margin-top: 200px;
  min-width: 320px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  width: 64%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flexBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.boxLeft {
  width: 100%;
  display: flex;
  justify-content: center;
}

.boxRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn {
  width: 254px;
  margin-top: 20px;
}

.passSummary {
  width: 100%;
  margin-bottom: 25px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoFormBox {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.header {
  margin-top: 20px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}

.title {
  font-size: 14px;
  font-weight: 500;
  margin-left: 4%;
}

.btnEdit {
  position: absolute;
  right: 16px;
}

.tableBox {
  width: 100%;
  margin-top: 20px;
}

.tableBox th {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 4px;
  padding-left: 0px;
  padding-right: 0px;
}

.tableBox tr {
  height: 50px;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  width: 50%;
  float: left;
}

.flexBox {
  flex: 1;
}

.tableBox td:not(.personalInfoDetail) {
  color: #000;
  font-weight: 500;
  margin-bottom: 5px;
}

.personalInfoDetail {
  margin-left: 0;
  background-color: #fff;
  border: none;
  font-size: 16px;
  height: 26px;
  /* text-indent: 4px; */
  outline-color: var(--specialGrey);
  margin-bottom: 2px;
}

.personalInfoDetail {}

.reviewTitle {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 500;
  text-align: center;
}

.subTitle {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 32px;
}

.documentBtn {
  width: 254px;
}

.documentBtnBox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;

}

.documentBtnBox button {
  margin-bottom: 40px;
  width: calc(100% - 50px);
}

.paymentBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paymentInfoBox {
  padding: 20px 49px 20px;
  border: 2px solid var(--darkGrey);
  border-radius: 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
}

.paymentTitle {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.innerBox {
  width: 100%;
}

.inputShortBox {
  display: flex;
  justify-content: space-between;
}

.inputLong {
  margin-bottom: 10px;
}

.inputShort {
  width: 48%;
  margin-bottom: 10px;
}

.cardBox {
  width: 100%;
  margin-top: 20px;
}

.cardInnerBox {
  margin-left: 30px;
}

.cardTitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.imgBox {
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
  margin-bottom: 10px;
}

.cardDescription {
  font-size: 14px;
}

.img {
  width: 50px;
}

.hidden {
  display: none;
}

.manageInfoText {
  color: var(--darkGrey);
  font-weight: 500;
  border-bottom: 1px solid var(--lightGrey);
  padding: 5px 0px;
}

.divider {
  border: 1px solid;
}

.lightColor td{
  color: grey !important;
  font-weight: 500;
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .box {
    width: 80%;
    margin-top: 230px;
  }

  .cardInnerBox {
    margin-left: 0px;
  }

  .imgBox {
    margin-right: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .flexBox {
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
  }

  .box {
    width: 100%;
    border-radius: 0px;
    margin-top: 230px;
    min-width: 320px;
  }

  .boxLeft {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .boxRight {
    width: 100%;
  }

  .btn {
    width: 200px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .documentBtn {
    width: 200px;
  }

  .cardInnerBox {
    margin-left: 60px;
    margin-right: 60px;
  }

  .imgBox {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) {
  .flexBox {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .box {
    box-sizing: border-box;
    width: 100%;
    border-radius: 0px;
    margin-top: 44px;
    min-width: 320px;
    padding: 10px;
  }

  .boxLeft {
    width: 80%;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .boxRight {
    width: 80%;
  }

  .btn {
    width: 200px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .infoFormBox {
    width: 100%;
  }

  .personalInfoDetail {
    margin-left: 0;
    font-size: 14px;
  }

  .tableBox th {
    text-align: left;
    font-size: 15px;
    font-weight: 500;
  }

  .subTitle {
    margin-bottom: 15px;
  }

  .documentBtn {
    width: 200px;
  }

  .paymentTitle {
    font-size: 16px;
  }

  .inputShortBox {
    flex-direction: column;
  }

  .inputShort {
    width: 100%;
  }

  .paymentInfoBox {
    margin-top: 20px;
    box-sizing: border-box;
    width: 98%;
  }

  .cardInnerBox {
    margin-left: 0px;
    margin-right: 0px;
  }

  .cardTitle {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .cardBox {
    display: flex;
    justify-content: center;
  }

  .cardInnerBox {
    width: 90%;
  }

  .img {
    width: 50px;
  }

  .cardDescription {
    font-size: 14px;
  }
}