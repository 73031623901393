.loader {
	border: 16px solid var(--darkGrey);
	border-top: 16px solid var(--mainColor);
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
	margin-top: 50px;
}

.container {
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	min-width: 320px;
}

.box {
	border-radius: 20px;
	margin-top: 200px;
	min-width: 320px;
	box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, .25);
	width: 64%;
	padding-left: 8%;
	padding-right: 8%;
	padding-bottom: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@media screen and (min-width:1200px) {}

@media screen and (min-width:992px) and (max-width:1199px) {}

@media screen and (min-width:768px) and (max-width:991px) {
	.box {
		width: 100%;
		border-radius: 0px;
		margin-top: 230px;
		min-width: 320px;
	}
}

@media screen and (max-width:767px) {
	.box {
		box-sizing: border-box;
		width: 100%;
		border-radius: 0px;
		margin-top: 44px;
		min-width: 320px;
		padding: 10px;
		padding-bottom: 20px;

	}

	.loader {
		border: 10px solid var(--darkGrey);
		border-top: 10px solid var(--mainColor);
		border-radius: 50%;
		width: 60px;
		height: 60px;
		animation: spin 2s linear infinite;
		margin-top: 20px;
	}
}