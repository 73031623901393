* {
    margin: 0;
    padding: 0;
  }
  .container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 320px;
  }
  .box {
    width: 82%;
    display: flex;
    margin-top: 200px;
    border-radius: 20px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  }
  .tabContainer {
   padding: 10px;
  }
  .topLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
  }
  .infoBox {
    width: 100%;
  }
  .detail {
   margin:10px
   }
  .subHeader {
    display: flex;
    align-items: center;
    height: 103px;
    border-radius: 0 20px 0 0;
  }
  h2 {
    display: block;
    font-size: 19px;
    font-weight: bold; 
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: initial;
  }
  .tabBtn {
    font-family: Roboto !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      line-height: 20px !important;
      letter-spacing :0.1px;
      text-align: center;
      color: #9C9C9C;
  }
  .saveBtnMbl {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding: 10px;
  }
  .title {
    font-size: 35px;
    font-weight: 500;
  }
  .arrow_left svg {
    margin-top: 0px;
    width: 40px;
    height: 20px;
    margin: 0px !important;
    display: block;
    color: #faf1f1;
  }
  
  .arrow_left {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }
  
  @media screen and (min-width: 1200px) {
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .box {
      margin-top: 230px;
      width: 98%;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .box {
      width: 100%;
      display: flex;
      margin-top: 230px;
      border-radius: 0px;
    }
  }
  @media screen and (max-width: 767px) {
    .box {
      width: 100%;
      display: flex;
      margin-top: 0px;
      border-radius: 0px;
      flex-direction: column;
    }
  }
  