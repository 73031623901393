.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}

.box {
  width: 82%;
  margin-top: 200px;
  border-radius: 20px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
}

.box__subHeader {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 20px 20px 0 0;
  height: 60px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.box__circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 45px;
  margin-right: 17px;
}

.box__title {
  font-size: 30px;
  font-weight: 500;
  margin: auto;
}

.box__flexBox {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  border-radius: 0 0 20px 20px;
  padding-top: 0px;
  padding-bottom: 108px;
}

.passBox {
  width: 70%;
  border-radius: 20px 20px 0px 0px;
  padding: 0px 45px 25px 0px;
}

.passForm {
  width: 100%;
  padding: 0 25px;
}

.radiusBox {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
}

.tableHeader {
  width: 100%;
  height: 74px;
  border-radius: 20px 20px 0px 0px;
}

.tableHeader__item {
  height: 74px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 10px;
  width: 12.5%;
}

.table {
  border-radius: 20px;
  width: 100%;
  text-align: center;
  border-collapse: separate;
}

.table tr th {
  background: var(--maincolor);
}

.dataLine {
  height: 74px;
  background-color: #fff;
}

.dataLine td {
  height: 74px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.resendBtn {
  width: 108px;
  height: 25px;
  border-radius: 10px;
  border: 1px solid var(--darkGrey);
  outline-style: none;
  cursor: pointer;
}

.resendBtn:hover {
  background-color: black;
  transition: 0.4s;
  color: white;
}

.deleteBtn {
  border: none;
  width: 108px;
  height: 25px;
  border-radius: 10px;
  outline-style: none;
  cursor: pointer;
}

.deleteBtn:hover {
  background-color: black;
  transition: 0.4s;
}

.alertBox {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #fff;
}

.popupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px;
}

.warning {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}

.instruction {
  text-align: center;
  max-width: 600px;
  line-height: 150%;
  font-size: 18px;
  margin-bottom: 18px;
  font-weight: 700;
}

.btn {
  width: 254px;
  margin: 0 40px;
  margin-bottom: 8px;
}

.alertPic svg {
  width: 54px;
  height: 54px;
}

.flex {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .box {
    margin-top: 230px;
    width: 98%;
  }

  .box__flexBox {
    flex-direction: column;
    align-items: center;
  }

  .passBox {
    width: 80%;
    border-radius: 20px 20px 0px 0px;
    padding: 0;
    order: -1;
    margin-bottom: 40px;
  }

  .passForm {
    width: 60%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .box {
    width: 100%;
    margin-top: 230px;
  }

  .box__flexBox {
    flex-direction: column;
    align-items: center;
    border-radius: 0px;
  }

  .box__subHeader {
    border-radius: 0px;
  }

  .passBox {
    width: 80%;
    border-radius: 0px 0px 0px 0px;
    padding: 0;
    order: -1;
    margin-bottom: 40px;
  }

  .passForm {
    width: 60%;
  }

  .btn {
    width: 200px;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 767px) {
  .box {
    width: 100%;
    margin-top: 44px;
  }

  .tableHeader__item {
    font-size: 14px;
    padding: 0 10px;
  }

  .table {
    border-radius: 0px;
  }

  .box__flexBox {
    flex-direction: column;
    align-items: center;
    border-radius: 0;
  }

  .passBox {
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    padding: 0;
    order: -1;
    margin-bottom: 40px;
  }

  .box__circle {
    width: 16px;
    height: 16px;
    margin-left: 20px;
  }

  .box__title {
    font-size: 20px;
    font-weight: 500;
  }

  .tableHeader {
    border-radius: 0px;
  }

  .box__subHeader {
    border-radius: 0px;
  }

  .passBox {
    border-radius: 0px;
  }

  .deleteBtn {
    width: 80px;
  }

  .resendBtn {
    width: 80px;
  }

  .radiusBox {
    border-radius: 0px;
    overflow: visible;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  }

  .passForm {
    width: 100%;
    box-sizing: border-box;
  }

  .popupContainer {
    padding: 10px 10px;
  }

  .warning {
    font-size: 20px;
  }

  .instruction {
    font-size: 16px;
  }

  .alertPic svg {
    width: 44px;
    height: 44px;
  }

  .btn {
    width: 200px;
    margin-bottom: 8px;
  }

  .formBox {
    padding: 0px 10px;
  }

  .flex {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.arrow_left svg {
  margin-top: 0px;
  width: 20px;
  height: 20px;
  margin: 0px !important;
  display: block;
  color: #000;
}

.arrow_left {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ddd;
  border-radius: 100%;
  margin-left: 10px;
}