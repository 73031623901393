* {
    margin: 0;
    padding: 0;
}

.container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 320px;
}

.box {
    display: flex;
    flex: 0.96;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 200px;
    background: #fff;
    padding: 40px;
    border-radius: 30px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
    min-height: calc(100vh - 320px);
}

.box__innerBox .white {
    height: auto;
    display: none;
    min-height: 230px;
}

.box__innerBox {
    margin: 10px 10px;
    flex: 0.2;
    min-width: 230px;
}

.displayNone {
    display: none;
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1199px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .box {
        flex-wrap: wrap;
        margin-top: 200px;
    }

    .box__innerBox {
        margin: 10px 10px;
    }

    .box__innerBox {
        flex: 0.1;
    }
}

@media screen and (max-width: 767px) {
    .box {
        flex-direction: column;
        min-width: 320px;
        flex: 0.6;
        margin-bottom: 20px;
        margin-top: 64px;
    }

    .box__innerBox {
        margin-top: 10px;
    }

    .box__innerBox {
        margin: 10px 30px;
    }
}

.custom_card>div {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    font-size: 30px;
}

.heading h3 {}

.heading h3 span {
    color: var(--mainColor);
}

.heading h3+span {
    font-size: 20px;
    margin-top: 20px;
    display: block;
}