.infoBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    margin-top: 24px;
    text-align: center;
}

.subTitle {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 18px;
}

.textBold {
    font-weight: 700;
}

.collectionBox {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.btnText {
    font-size: 14px;
    font-weight: 500;
}

.btnBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.btnText {
    margin-top: 1px;
}

.btn {
    width: 254px;
    margin-top: 20px;
}

.btnBox button {
    width: 25px;
    height: 25px;
    outline-style: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
}

.addBtnImg {
    width: 25px;
    height: 25px;
    fill: var(--secondaryColor);
    margin-top: 0px;
}

.infoFormBox {
    width: 80%;
    margin-bottom: 30px;
}

.inputShortBox {
    display: flex;
    justify-content: space-between;
}

.inputShort {
    width: 30%;
    margin-top: 10px;
    text-align: center;
}

.inputLong {
    width: 100%;
    margin-top: 10px;
}
.genericBtn {
    width: 30px;
    border-radius: 0px 20px 20px 0px;
    background-color: black;
    color: white;
}
.qtyInput {
    width: 32px;
    height: 22px;
    border-left: none;
    border-right: none;
    border: 1px solid var(--mainColor);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    outline-style: none;
}

.qtyBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.labelFont {
    font-size: 14px;
    font-weight: 500;
}

.leftCircle {
    border: none;
    height: 24px;
    width: 32px;
    border-radius: 20px 0px 0px 20px;
    font-size: large;
    font-weight: 600;
    outline-style: none;
    cursor: pointer;
    padding-bottom: 3px;
    color: white;
    background-color: black;
}

.rightCircle {
    border: none;
    height: 24px;
    width: 32px;
    border-radius: 0px 20px 20px 0px;
    font-size: large;
    font-weight: 600;
    outline-style: none;
    cursor: pointer;
    padding-bottom: 3px;
    color: white;
    background-color: black;
}

.quanityBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
}

.contentTitle {
    font-size: 16px;
    font-weight: 600;
}

@media screen and (min-width:1200px) {}

@media screen and (min-width:992px) and (max-width:1199px) {}

@media screen and (min-width:768px) and (max-width:991px) {
    .btn {
        width: 200px;
        margin-top: 40px;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width:767px) {
    .infoFormBox {
        width: 100%;
        margin-bottom: 30px;
    }

    .btn {
        width: 200px;
        margin-top: 25px;
        display: flex;
        justify-content: center;
    }

    .collectionBox {
        margin-top: 15px;
    }

    .btnBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
    }
}