.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1999;
  width: 100%;
  height: 112px;
  min-width: 320px;
  box-sizing: border-box;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}
.footer__policy {
  margin-bottom: 10px;
  padding: 0 10px;
  text-decoration: underline;
}
.footer__policy a:hover {
  color: white;
  transition: 0.4s;
}

.footer__title {
  font-size: 17px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}
.footer__link {
  padding-right: 5px;
  padding-left: 5px;
  text-decoration: underline;
}
.titleName {
  padding-left: 5px;
  padding-right: 5px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
  .footer {
    height: 80px;
    text-align: center;
    line-height: 150%;
  }
  .footer__title {
    font-size: 12px;
  }
  .titleName {
    padding-left: 5px;
    padding-right: 5px;
  }
  .footer__policy {
    font-size: 12px;
    padding: 0 5px;
  }
}
