* {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
.cardBox {
  flex: 1;
  flex-grow: 1;
  height: 100%;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  /* min-height: 380px; */
  height: 100%;
  box-sizing: border-box;
  min-width: 230px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 0 1px;
  padding-bottom: 80px;
}

.card__title {
  flex: 0.14;
  font-size: 20px;
  font-weight: 600;
  margin-top: 21px;
  margin-bottom: 16px;
}

.card__description {
  font-size: 16px;
  font-weight: 600;
  /* max-width: 270px; */
  width: 60%;
  min-height: 30px;
  height: fit-content;
  line-height: 150%;
  text-align: center;
}
.card__description.desc_left {
  text-align: left;
}
.card__description.desc_center {
  text-align: center;
}
.card__sub_list {
  list-style-type: disc;
  list-style-position: outside;
  width: 50%;
  display: block;
}
.card__sub_description {
  list-style-type: disc;
  list-style-position: outside;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  min-height: 20px;
  height: fit-content;
  text-align: left;
}
.card__btn {
  position: absolute;
  bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  flex: 0.41;
  margin-top: 60px;
}
.card__btnInnerBox {
  display: flex;
  flex: 0.8;
}
.card svg {
  margin-top: 20px;
  flex: 0.33;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .card {
    /* height: 360px; */
  }
  .card svg {
    margin-top: 5px;
    width: 90px;
  }
  .card__title {
    margin-top: 5px;
  }
  .card__description {
    /* height: 80px; */
    height: fit-content;
  }
  .card__btn {
    /* margin-top: 40px; */
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .card {
    /* height: 340px; */
  }
  .card__description {
    /* height: 80px; */
    height: fit-content;
  }
  .card__btn {
    /* margin-top: 20px; */
  }
  .card__title {
    margin-top: 0px;
  }
  .card__description {
    font-size: 14px;
    max-width: 200px;
  }
  .card__sub_description {
    font-size: 14px;
  }
  .card svg {
    margin-top: 20px;
    width: 80px;
  }
}

@media screen and (max-width: 767px) {
  .card {
    /* height: 320px; */
    border-radius: 20px;
  }
  .card__btn {
    /* margin-top: 40px; */
  }
  .card__description {
    font-size: 12px;
    max-width: 200px;
  }
  .card__sub_description {
    font-size: 12px;
  }
  .card__title {
    margin-top: 5px;
  }
  .card svg {
    margin-top: 5px;
    width: 80px;
    height: 100px;
  }
}
