
.wrapper {
    font-size: small;
    margin: 0.25rem 0;
    width: max-content;
}

.wrapper b {
    font-size: large;
}

.wrapper ul {
    padding-left: 1rem;
}
.wrapper li {
    list-style: square;
    margin-top: 0.5rem;
    margin-bottom: 0;
}
.wrapper a {
    color: inherit;
    text-decoration: underline;
}