.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}
.box {
  width: 82%;
  margin-top: 200px;
  border-radius: 20px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
}
.box__subHeader {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 20px 20px 0 0;
  height: 103px;
}
.box__circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 45px;
  margin-right: 17px;
}
.box__title {
  font-size: 35px;
  font-weight: 500;
}
.box__flexBox {
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 0 0 20px 20px;
  padding-top: 50px;
  padding-bottom: 56px;
}
.box__innerBox {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box__paragraph {
  text-align: center;
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 58px;
}
.box__codeBox {
}
.box__codeTitle {
  font-size: 18px;
  margin-bottom: 19px;
}
.box__code {
  display: flex;
  justify-content: space-between;
  width: 662px;
  margin-bottom: 154px;
}
.box__singleCode {
  width: 70px;
  height: 76px;
  font-size: 35px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnbox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.btn {
  width: 30%;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .box {
    width: 98%;
    margin-top: 230px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .box {
    width: 100%;
    margin-top: 230px;
    border-radius: 0;
  }
  .box__subHeader {
    border-radius: 0;
  }
  .box__flexBox {
    border-radius: 0;
    padding-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .box {
    width: 100%;
    margin-top: 44px;
    border-radius: 0;
  }
  .box__flexBox {
    border-radius: 0;
    padding-top: 20px;
  }
  .box__innerBox {
    width: 92%;
  }
  .box__subHeader {
    border-radius: 0;
  }
  .box__circle {
    width: 16px;
    height: 16px;
    margin-left: 20px;
  }
  .box__title {
    font-size: 20px;
    font-weight: 500;
  }
  .box__paragraph {
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 30px;
  }
  .btnbox {
    width: 200px;
  }
  .btn {
    width: 100%;
  }
  .box__codeTitle {
    font-size: 16px;
    margin-bottom: 19px;
  }
  .box__code {
    display: flex;
    justify-content: space-between;
    width: 268px;
    margin-bottom: 80px;
  }
  .box__singleCode {
    width: 34px;
    height: 37px;
    font-size: 18px;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
