.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  align-items: center;
  row-gap: 10px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
}
.header {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--mainColor);
  height: 40px;
  padding-top: 10px;
}
.header h2 {
  color: white;
  font-weight: 500;
  padding: 0px;
  padding-left: 30px;
}
.wrapper label {
  display: flex;
  flex-direction: column;
  color: #75787c;
  font-size: 17px;
}
.wrapper select {
  margin: 8px 0px;
  width: 234.67px;
  padding: 8px 0px;
  background-color: #e9e9e9;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline-style: none;
  font-size: 14px;
  margin-top: 0;
}
.loadContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}