.infoBox {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 500;
}
.subTitle {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
}
.btnIcon svg {
  fill: var(--mainColor);
  height: 24px;
}
.btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.btnText {
  font-weight: 500;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .infoBox {
    width: 100%;
  }
}
