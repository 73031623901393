.box {
  width: 100%;
}
.flexBox {
  width: 100%;
  box-sizing: border-box;
  min-width: 320px;
  display: flex;
  justify-content: center;
}
.passCollectionBox {
  width: 50%;
}
.receiptBox {
  position: relative;
  width: 50%;
  max-width: 508px;
  margin-left: 4%;
  margin-right: 4%;
  margin-top: 40px;
  border-radius: 20px;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
  border: 1px solid rgb(226, 224, 224);
  height: 686px;
  overflow: hidden;
  min-width: 320px;
}
.shapeBig {
  position: absolute;
  width: 0;
  height: 0;
  border-right: 600px solid transparent;
  border-top: 133px solid var(--mainColor);
}
.infoBox {
  margin-top: 151px;
  padding: 0 3%;
}
.shapeSmall {
  position: absolute;
  left: 63%;
  width: 0;
  height: 0;
  border-right: 400px solid transparent;
  border-top: 100px solid rgb(243, 243, 243);
  opacity: 0.5;
}
.circle {
  position: absolute;
  left: calc(50% - 42px);
  top: 44px;
  width: 84px;
  height: 84px;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
  border-radius: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  width: 60px;
}
.receiptTitle {
  font-size: 18px;
  text-align: center;
  font-weight: 500;
}
.namePicker {
  margin-left: 5px;
}
.receiptNumber {
  font-size: 14px;
  text-align: center;
  margin-top: 6px;
}
.infoDetailBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.infoTitle {
  font-size: 13px;
  font-weight: 500;
  color: var(--mediumGrey);
  margin-top: 20px;
}
.infoTitle li {
  list-style: none;
  margin-top: 5px;
}
.cardName {
  color: black;
  font-weight: 700;
  font-size: 14px;
}
.summary {
  margin-top: 30px;
  margin-bottom: 10px;
}
.tableSummary {
  width: 100%;
  height: 84px;
}
.tableSummary tr th {
  text-align: start;
  color: var(--mediumGrey);
  font-weight: 500;
  font-size: 13px;
  padding-left: 5px;
  height: 42px;
}
.thWithLine {
  border-bottom: 1px solid var(--specialGrey);
}
.namePickerSmall {
  margin-left: 1px;
}
.tableSummary tr td {
  text-align: start;
  color: var(--mediumGrey);
  font-weight: 500;
  font-size: 13px;
  height: 42px;
}
.line {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 1px;
  width: 100%;
}
.contactText {
  font-size: 13px;
  font-weight: 500;
  line-height: 150%;
}
.problemText {
  font-size: 13px;
  line-height: 150%;
  margin-bottom: 10px;
}
.btnBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.btn {
  width: 254px;
}
.thShort {
  width: 100px;
}
.thLong {
  width: 40%;
}
.hidden {
  display: none;
}

@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .flexBox {
    justify-content: space-between;
    flex-direction: column;
  }
  .passCollectionBox {
    width: 100%;
    border-top: 2px solid rgb(230, 229, 229);
    border-bottom: 2px solid rgb(230, 229, 229);
    margin-top: 20px;
  }
  .receiptBox {
    width: 50%;
    margin-left: 0;
    margin: 0 auto;
    margin-top: 40px;
    border-radius: 20px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .flexBox {
    justify-content: space-between;
    flex-direction: column;
  }
  .passCollectionBox {
    width: 100%;
    border-top: 2px solid rgb(230, 229, 229);
    border-bottom: 2px solid rgb(230, 229, 229);
    margin-top: 20px;
  }
  .receiptBox {
    width: 50%;
    margin-left: 0;
    margin: 0 auto;
    margin-top: 40px;
    border-radius: 20px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  }
  .btn {
    width: auto;
  }
}
@media screen and (max-width: 767px) {
  .flexBox {
    justify-content: space-between;
    flex-direction: column;
  }
  .passCollectionBox {
    width: 100%;
    border-top: 2px solid rgb(230, 229, 229);
    margin-top: 20px;
  }
  .receiptBox {
    width: 100%;
    margin-left: 0;
    margin: 0 auto;
    margin-top: 10px;
    border-radius: 0px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  }
  .shapeBig {
    border-right: 500px solid transparent;
  }
  .shapeSmall {
    position: absolute;
    left: 65%;
    width: 0;
    height: 0;
    border-right: 300px solid transparent;
    border-top: 100px solid rgb(243, 243, 243);
    opacity: 0.5;
  }
  .btn {
    width: auto;
  }
}
