.inviteBox {
  width: 93%;
}

.formBox {
  display: flex;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0px 20px 20px 20px;
  margin-top: 39px;
}
.recipientTitle {
  background-color: var(--mainColor);
  position: absolute;
  top: -29px;
  height: 29px;
  border-radius: 10px 10px 0px 0px;
  padding: 0px 15px;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.formLayout {
  display: flex;
  padding: 10px;
  width: 100%;
}
.formLayoutShort {
  display: flex;
  padding: 10px;
  max-width: 850px;
  width: 100%;
}
.instruction {
  font-size: 15px;
  width: 18%;
  margin-right: 20px;
  line-height: 150%;
  min-width: 180px;
  font-weight: 500;
}
.guestInfo {
  width: 30%;
  margin-right: 20px;
  min-width: 200px;
}
.guestInfo input {
  margin-bottom: 8px;
}

.inputShortBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.inputShort {
  width: 49%;
}
.rentals {
  width: 100%;
  min-width: 300px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rentalsAuto {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rentalItems {
  background-color: #f8f8f8;
  display: flex;
  justify-content: flex-start;
  border-radius: 3px;
  padding-right: 8px;
  margin-bottom: 8px;
}
.number {
  min-width: 43px;
  background-color: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.rentals input {
  background-color: transparent;
  margin: 8px 0px;
}
.rentalsAuto input {
  background-color: transparent;
  margin: 8px 0px;
}
.searchInput {
  min-width: 120px;
  margin-right: 5px;
}
.searchInput input {
  display: flex;
  height: 23px;
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--lightGrey);
  outline-style: none;
  font-size: 14px;
}
.searchInput input:focus {
  border-bottom: 2px solid var(--mainColor);
}

.searchInput label {
  font-size: 17px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: var(--mediumGrey);
}

.dateInput {
  min-width: 100px;
  margin-right: 10px;
}
.dateinputShortBox {
  display: flex;
}
.rentalInputBox {
  display: flex;
  flex-wrap: wrap;
}
.delRentalBtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  background: url(../../assets/delSmall.svg) no-repeat center;
  color: transparent;
  width: 10px;
}
.delBtn {
  background-color: white;
  border: none;
  color: transparent;
  width: 20px;
  height: 20px;
  background: url(../../assets/deleteRed.svg) no-repeat center;
  cursor: pointer;
}
.addRentalBtn button {
  background-color: var(--mainColor);
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-weight: 500;
}
.check {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.check label {
  display: flex;
  flex-direction: row-reverse;
  font-size: 15px;
  line-height: 130%;
  font-weight: 500;
}
.check input {
  margin-right: 10px;
  appearance: none;
  width: 21px;
  height: 21px;
  border: 2px solid var(--lightGrey);
  cursor: pointer;
}
.check input:checked {
  background: url(../../assets/checked.svg) no-repeat center;
}
.btnBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.inviteBtn button {
  width: 254px;
  height: 43px;
  border: none;
  background-color: var(--thirdColor);
  border-radius: 10px;
  color: white;
  font-weight: 500;
  font-size: 18px;
}
.inviteBtn button:hover {
  background-color: var(--secondaryColor);
  transition: 0.4s;
}
.addBtn {
  /* width: 100px;
    height: 24px; */
  margin-top: 20px;
}
.addBtn button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.addBtn button h4 {
  color: var(--mainColor);
  font-size: 15px;
  font-weight: 500;
  margin-top: 4px;
}
.addBtn svg {
  fill: var(--mainColor);
  margin-top: 0px;
  width: 24px;
  max-height: 24px;
}
.paymentInstruction {
  display: flex;
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 60px;
}
.item {
  display: flex;
}
.itemCheck {
  margin-right: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.itemCheckbox {
  margin-right: 10px;
  appearance: none;
  width: 21px;
  height: 21px;
  border: 2px solid var(--lightGrey);
  cursor: pointer;
}
.itemCheckbox:checked {
  background: url(../../assets/checked.svg) no-repeat center;
}
.passTypeLabel {
  display: flex;
  flex-direction: column;
  color: #75787c;
  font-size: 17px;
}
.passTypeLabel select {
  margin: 8px 0px;
  width: 234.67px;
  padding: 8px 0px;
  background-color: #e9e9e9;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline-style: none;
  font-size: 14px;
  margin-top: 0;
}

.delRentalBtnHidden {
  visibility: hidden;
}
.uploadBtn {
  margin-top: 10px;
  margin-bottom: 30px;
}
.uploadBtn button {
  width: 254px;
  height: 43px;
  border: none;
  background-color: var(--mainColor);
  border-radius: 10px;
  color: white;
  font-weight: 500;
  font-size: 18px;
}
.uploadBtn button:hover {
  background-color: var(--secondaryColor);
  transition: 0.4s;
}
.dateInput input {
  width: 125px;
}
.rentalInnerBox {
  padding: 0px 10px 10px 10px;
  background-color: #f8f8f8;
}
.addBtnBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.firstLine {
  display: flex;
  margin-bottom: 8px;
}
.firstLineContent {
  display: flex;
  flex-wrap: wrap;
}
.rightBox {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.delBoxHidden {
  visibility: hidden;
}
.fastPassInstruction {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f4f4f4;
  padding: 16px 10px;
  border-radius: 6px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
}
.inviteGuestInstruction {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f4f4f4;
  padding: 16px 10px;
  border-radius: 6px;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
  line-height: 150%;
}
.companyNameInpt label {
  font-size: 17px;
  color: var(--mediumGrey)
}
.companyNameInpt input {
  outline: none;
  border-width: 0px;
  border-bottom-width: 2px;
  border-color: var(--lightGrey);
  font-size: 14px;
  height: 23px;
}
.companyNameInpt input:focus {
  color: var(--mainColor)
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .formLayout {
    flex-direction: column;
    align-items: center;
  }
  .formLayoutShort {
    flex-direction: column;
    align-items: center;
    max-width: 800px;
  }
  .instruction {
    font-size: 15px;
    width: 90%;
    text-align: center;
    margin-right: 0px;
    line-height: 120%;
    min-width: 200px;
    margin-bottom: 20px;
  }
  .guestInfo {
    width: 90%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .rentals {
    width: 90%;
    margin-bottom: 20px;
    margin-right: 0px;
  }
  .rentalsAuto {
    margin-right: 0px;
  }
  .check {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .passTypeLabel select {
    margin: 0px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .inviteBox {
    width: 100%;
  }
  .formLayout {
    flex-direction: column;
    align-items: center;
  }
  .formLayoutShort {
    flex-direction: column;
    align-items: center;
    max-width: 800px;
  }
  .instruction {
    font-size: 15px;
    width: 90%;
    text-align: center;
    margin-right: 0px;
    line-height: 120%;
    min-width: 200px;
    margin-bottom: 20px;
  }
  .guestInfo {
    width: 90%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .rentals {
    width: 90%;
    margin-bottom: 20px;
    margin-right: 0px;
  }
  .rentalsAuto {
    margin-right: 0px;
  }
  .check {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .passTypeLabel select {
    margin: 0px;
    margin-bottom: 8px;
  }
  .rightBox {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .inviteBox {
    width: 100%;
  }
  .formLayout {
    flex-direction: column;
    align-items: center;
  }
  .formLayoutShort {
    flex-direction: column;
    align-items: center;
    max-width: 800px;
  }
  .instruction {
    font-size: 15px;
    width: 90%;
    text-align: center;
    margin-right: 0px;
    line-height: 120%;
    min-width: 200px;
    margin-bottom: 20px;
  }
  .guestInfo {
    width: 90%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .rentals {
    width: 90%;
    margin-bottom: 20px;
    margin-right: 0px;
  }
  .rentalsAuto {
    margin-right: 0px;
  }
  .check {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .paymentInstruction {
    display: flex;
    width: auto;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: 60px;
  }
  .searchInput label {
    font-size: 14px;
  }
  .passTypeLabel select {
    margin: 0px;
    margin-bottom: 8px;
  }
  .rightBox {
    width: 100%;
  }
  .fastPassInstruction {
    font-size: 14px;
  }
}
