.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}

.box {
  border-radius: 20px;
  margin-top: 200px;
  min-width: 320px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  width: 64%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flexBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.boxLeft {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.boxRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn {
  width: 254px;
  margin-top: 20px;
}

.passDisplay {
  padding: 30px 49px 30px;
  border: 2px solid var(--darkGrey);
  border-radius: 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  margin-top: 20px;
}

.hidden {
  display: none;
}

.infoBox {
  padding: 30px 49px 30px;
  border: 2px solid var(--darkGrey);
  border-radius: 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0px;
}

.title {
  font-size: 24px;
  margin-bottom: 7px;
  text-align: center;
}

.text {
  text-align: center;
  font-size: 14px;
  margin-bottom: 36px;
}

.tableBox {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  margin-bottom: 20px;
}

.tableBox th {
  padding-right: 10px;
  font-weight: 500;
}

.tableBox tr {
  margin-bottom: 26px;
}

.btnPrint {
  width: 254px;
  margin-bottom: 20px;
}

.passNum {
  list-style: none;
}

.summaryText {
  text-align: center;
  line-height: 150%;
  font-weight: 500;
  margin-bottom: 20px;
}

.btnPrint {
  width: 254px;
  margin-bottom: 20px;
}

.linkBox {
  display: flex;
  justify-content: center;
}

.link {
  text-align: justify;
  word-break: break-all;
  text-decoration: underline;
  color: blue;
}

.rentalPassBox {
  margin-top: 10px;
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .passList {
    margin-top: 20px;
    width: 100%;
  }

  .box {
    width: 80%;
    margin-top: 230px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .flexBox {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .box {
    width: 100%;
    border-radius: 0px;
    margin-top: 230px;
    min-width: 320px;
  }

  .boxLeft {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .boxRight {
    width: 100%;
  }

  .btn {
    width: 200px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .passList {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .btnPrint {
    width: 200px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .flexBox {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .box {
    box-sizing: border-box;
    width: 100%;
    border-radius: 0px;
    margin-top: 44px;
    min-width: 320px;
    padding: 10px;
  }

  .infoBox {
    box-sizing: border-box;
    padding: 10px;
    border: 2px solid var(--darkGrey);
    border-radius: 0px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
  }

  .boxLeft {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .boxRight {
    width: 100%;
  }

  .btn {
    width: 200px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .btnPrint {
    width: 200px;
    margin-bottom: 20px;
  }
}