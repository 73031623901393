.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}
.box {
  border-radius: 20px;
  margin-top: 200px;
  min-width: 320px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  width: 64%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form {
  width: 58%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 525px;
}
.inputBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.inputShort {
  width: 48%;
}
.inputLong {
  width: 100%;
}
.btn {
  width: 254px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.alertBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.alert {
  font-size: 14px;
  text-align: center;
  margin-left: 8px;
}
.phoneInputContainer {
  width: 100%;
}
.phoneInputTitle {
  font-size: 18px;
}
.inputArea {
  width: 100% !important;
  border: none !important;
  border-bottom: 2px solid var(--lightGrey) !important;
  height: 38px !important;
  border-radius: 0 !important;
}
.inputArea:focus {
  border-bottom: 2px solid var(--mainColor) !important;
}
.countryBtn {
  border: none !important;
  border-bottom: 2px solid var(--lightGrey) !important;
  border-radius: 0px !important;
}
.ownerPinText {
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  background-color: var(--lightGrey);
  margin-top: 5px;
  color: var(--superDarkGrey);
  padding: 10px;
  line-height: 150%;
}

@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .box {
    width: 80%;
    margin-top: 230px;
  }
  .form {
    width: 60%;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .box {
    width: 100%;
    border-radius: 0px;
    margin-top: 230px;
    min-width: 320px;
  }
  .form {
    width: 70%;
  }
  .btn {
    width: 200px;
    display: flex;
    justify-content: center;
  }
  .phoneInputTitle {
    font-size: 16px;
  }
  .inputArea {
    height: 30px !important;
    font-size: 14px !important;
  }
}
@media screen and (max-width: 767px) {
  .box {
    box-sizing: border-box;
    width: 100%;
    border-radius: 0px;
    margin-top: 44px;
    min-width: 320px;
    padding: 10px;
  }
  .form {
    width: 98%;
    min-width: 320px;
  }
  .btn {
    width: 200px;
    display: flex;
    justify-content: center;
  }
  .inputBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
  }
  .inputShort {
    width: 100%;
    margin-bottom: 10px;
  }
  .inputLong {
    margin-bottom: 10px;
  }
  .phoneInputTitle {
    font-size: 16px;
  }
  .inputArea {
    height: 30px !important;
    font-size: 14px !important;
  }
}
