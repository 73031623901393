.loading {
    position: relative;
    margin-bottom: 30px;
    margin-top: 0;
    margin-left: 10px;
    font-weight: 600;
    color: var(--specialGrey);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loader {
    box-sizing: border-box;
    width: 100px;
    height: 20px;
    display: flex;
    justify-content: space-around;
    /* border: 1px solid grey; */
    position: relative;
}

/* .loader::before {
      position: absolute;
      content: '';
      left: 20px;
      right: 20px;
      border-top: 2px solid blue;
      top: 50%;
      transform: translateY(-50%);
  } */

.loader>div {
    width: 20px;
    height: 20px;
    background-color: var(--mainColor);
    transform: rotate(45deg);
    animation: loader-1 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate;
}

div.two {
    animation-delay: 200ms;
}

div.three {
    animation-delay: 400ms;
}

@keyframes loader-1 {
    from {
        transform: rotate(45deg) scale(1);
    }

    to {
        transform: rotate(45deg) scale(0.8);
    }
}

@media screen and (min-width: 1200px) {}

.custom_loader {
    width: 135px;
    height: 135px;
    border: 10px solid #ebebeb;
    border-bottom-color: var(--mainColor);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.modal_content {
    text-align: center;
}

.modal_content h2 {
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: normal;
}

.modal_content p {
    font-size: 16px;
    line-height: 1.5;
}

.loader_modal {
    width: 700px;
    display: flex;
    background: #fff;
    z-index: 99999;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: fixed;
    top: 50%;
    padding: 50px;
    border-radius: 10px;
    transform: translate(0px, -50%);
}