.ListBox {
  width: 93%;
}

.btnExport {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.btnExport button {
  width: 220px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.input select {
  min-width: 260px;
  border-radius: 5px;
  border: 1px solid rgb(209, 209, 209);
  height: 32px;
  outline-style: none;
  cursor: pointer;
  margin-top: 5px;
}
.btnBox {
  margin-top: 30px;
}
.dateInput {
  min-width: 100px;
  margin-right: 10px;
}
.dateinputShortBox {
  display: flex;
  align-items: flex-end;
}
.selectBtnBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
  margin-top: 40px;
  width: 60%;
}
.selectBtnBox button {
  width: 561px;
  padding: 0px 20px;
}
.btnSpacer {
  height: 0.8rem;
}
.selectBtnActive button {
  background-color: var(--mainColor);
  color: white;
}
.selectBtnInactive button {
  color: #636365;
}
.selectBtnInactive button:hover {
  background-color: var(--mainColor);
  color: white;
}
.dateInput input {
  background-color: #f1f1f1;
  padding: 8px 0px;
  width: 200px;
  padding-left: 10px;
  padding-right: 10px;
  color: black;
  font-weight: 500;
}
.rectBox {
  height: 43px;
  width: 20px;
  display: flex;
  align-items: center;
}
.rect {
  height: 4px;
  width: 17px;
  background-color: #d9d9d9;
  margin-right: 10px;
}

@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .ListBox {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .ListBox {
    width: 100%;
  }
  .selectBtnBox {
    width: 100%;
  }
  .selectBtnBox button {
    width: auto;
    max-width: 459px;
    padding: 10px 20px;
    height: auto;
  }
  .dateinputShortBox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .rect {
    height: 4px;
    width: 17px;
    background-color: #d9d9d9;
    margin-right: 0px;
    margin-top: 10px;
  }
}
