.formWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  align-items: stretch;
  justify-content: stretch;
}
.halfWidth {
  grid-column: span 1;
}
.fullWidth {
  grid-column: span 2;
}

@media screen and (max-width:992px) {
  .halfWidth {
    grid-column: span 2;
  }
}