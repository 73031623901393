.spacer {
    display: block;
    height: 10em;
  }
  .navHeaderBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .titleGrey {
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 500;
    margin-top: 32px;
  }
  .titleBlue {
    margin-left: 7px;
  }
  .text {
    font-size: 15px;
    margin-top: 10px;
    max-width: 855px;
    line-height: 150%;
    font-weight: 500;
  }
  .iconBox {
    display: flex;
    margin-top: 0px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .line {
    height: 4px;
    width: 68px;
    border-radius: 4px;
  }
  .title {
    margin-top: 30px;
    margin-bottom: 9px;
    font-size: 25px;
    font-weight: 500;
    max-width: 800px;
  }
  .subTitle {
    font-size: 16px;
    max-width: 574px;
    font-weight: 500;
    line-height: 150%;
  }
  .welcomeHidden {
    display: none;
  }
  .textHidden {
    display: none;
  }
  @media screen and (min-width: 1200px) {
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
  }
  @media screen and (max-width: 767px) {
    .iconBox {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    .line {
      height: 2px;
      width: 10%;
      border-radius: 4px;
    }
    .titleGrey {
      font-size: 20px;
      margin-top: 20px;
    }
    .text {
      font-size: 14px;
    }
    .iconBox {
      display: flex;
      margin-top: 15px;
      width: 100%;
      justify-content: space-evenly;
      align-items: center;
    }
    .title {
      margin-top: 30px;
      font-size: 20px;
    }
    .subTitle {
      font-size: 14px;
      max-width: 574px;
    }
  }
  