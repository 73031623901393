.flexBox {
  width: 100%;
  display: flex;
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.passBox {
  width: 100%;
}
.pass {
  width: 317px;
  /* box-shadow: 0px 1px 5px 0px rgba(0,0,0,.2); */
  /* border: 1px solid var(--specialGrey); */
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
  border: 1px solid rgb(226, 224, 224);
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 11px;
  padding-bottom: 14px;
  padding-left: 80px;
  padding-right: 80px;
  border-radius: 20px;
  margin-bottom: 10px;
}
.logoBox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.logo {
  height: 97px;
}
.title {
  text-align: center;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 500;
}
.subtitle {
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
}
.validFromBox {
  margin-top: 8px;
  padding-left: 7px;
  padding-bottom: 5px;
  border-bottom: 1px dashed #c4c4c4;
}
.validFrom {
  font-size: 14px;
  font-weight: 400;
  margin-right: 5px;
  margin-bottom: 5px;
}
.validFromItems {
  font-weight: 700;
  color: black;
  font-size: 14px;
}
.validToBox {
  margin-top: 8px;
  padding-left: 7px;
  padding-bottom: 5px;
}
.infoBox {
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 10px;
  margin-top: 12px;
}
.info {
  padding: 0px 5px;
  width: 40%;
}
.invisiableBox {
  width: 20%;
}
.Row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0px;
}
.name {
  font-size: 14px;
  font-weight: 400;
}
.nameitem {
  font-size: 14px;
  font-weight: 700;
}
.contentTitle {
  font-weight: 400;
  font-size: 14px;
}
.content {
  font-weight: 700;
  font-size: 14px;
}

.btnBox {
  display: flex;
  width: 34%;
  margin-left: 55%;
}
.btnShare {
  width: 254px;
  margin-right: 30px;
}
.btnDownload {
  width: 254px;
}
.footerTitle {
  font-size: 13px;
  text-align: center;
  margin-top: 24px;
}
.passForm {
  width: 38%;
  margin-left: 2%;
  margin-top: 40px;
}
.boxTop {
  width: 100%;
}
.qrCode {
  width: 100%;
}
.timeBox {
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 10px;
}
.btn {
  width: 245px;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .btn {
    width: 200px;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .title {
    font-size: 18px;
  }
  .subtitle {
    text-align: center;
    margin-top: 5px;
    font-size: 13px;
    font-weight: 500;
  }
  .container {
    width: 100%;
    padding: 0px;
  }
  .pass {
    box-sizing: border-box;
    max-width: auto;
    width: 100%;
    padding-top: 11px;
    padding-bottom: 14px;
    padding-left: 0px;
    padding-right: 0px;
    border-radius: 0px;
    margin: 0;
    margin-bottom: 10px;
  }
  .qrCode {
    width: 100%;
  }
  .logo {
    height: 60px;
  }
  .btn {
    width: 200px;
    display: flex;
    justify-content: center;
  }
}
