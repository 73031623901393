.wrapper {
  background-color: white;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 25px;
  margin-top: 200px;
}