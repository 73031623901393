.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}

.box {
  border-radius: 20px;
  margin-top: 200px;
  min-width: 320px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  width: 64%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flexBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.boxLeft {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.boxRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hidden {
  display: none;
}
.btn {
  width: 254px;
  margin-top: 20px;
}
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.infoBox {
  padding: 30px 30px 30px;
  border: 2px solid var(--darkGrey);
  border-radius: 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0px;
}
.title {
  font-size: 18px;
  font-weight: 500;
}
.tableContainer {
  width: 100%;
  border-bottom: 1px solid var(--lightGrey);
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.tableBox {
  width: 100%;
  margin-top: 20px;
}
.tdBox {
  text-align: left;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
}
.tdBoxSpace {
  text-align: left;
  width: 50%;
  font-weight: 500;
  font-size: 14px;
  min-width: 120px;
}
.trBox {
  width: 50%;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
}
.tdBoxRight {
  text-align: right;
  width: 50%;
}
.tableBelow {
  width: 100%;
  margin-bottom: 10px;
  color: var(--darkGrey);
}
.tableBelow th {
  text-align: left;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
  padding: 3px 0px;
}
.tableBelow td {
  text-align: left;
  width: 50%;
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
}
.totalBox {
  height: 50px;
  width: 100%;
  border: 2px solid var(--darkGrey);
  border-radius: 20px;
  display: flex;
  align-items: center;
}
.total {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.textLeft {
  font-size: 20px;
  margin-left: 5%;
  font-weight: 500;
  line-height: 65px;
}
.textRight {
  font-size: 20px;
  margin-right: 5%;
  font-weight: 500;
  line-height: 65px;
}

@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .box {
    width: 80%;
    margin-top: 230px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .flexBox {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .box {
    width: 100%;
    border-radius: 0px;
    margin-top: 230px;
    min-width: 320px;
  }
  .boxLeft {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .boxRight {
    width: 100%;
  }
  .btn {
    width: 200px;
    margin-top: 116px;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .flexBox {
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }

  .box {
    box-sizing: border-box;
    width: 100%;
    border-radius: 0px;
    margin-top: 44px;
    min-width: 320px;
    padding: 10px;
  }
  .boxLeft {
    width: 80%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .boxRight {
    width: 100%;
  }
  .btn {
    width: 200px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .totalBox {
    height: 40px;
  }
  .textLeft {
    font-size: 20px;
  }
  .textRight {
    font-size: 20px;
  }
}
