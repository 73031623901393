.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}

.box {
  border-radius: 20px;
  margin-top: 200px;
  min-width: 320px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, .25);
  width: 64%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flexBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.boxLeft {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.boxRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.passList {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.infoBox {
  border: 2px solid var(--darkGrey);
  border-radius: 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, .25);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0px;
  margin-top: 24px;
  width: 82%;
  overflow: hidden;
}

.innerInfoBox {
  padding: 30px 50px 30px;
}

.title {
  font-size: 24px;
  margin-bottom: 7px;
  text-align: center;
}

.text {
  text-align: center;
  font-size: 14px;
  margin-bottom: 36px;
}

ul li {
  list-style: none;
  margin-bottom: 26px;
}

ul li b {
  margin-right: 5px;
}

.infoBoxImg {
  max-width: 100%;
  margin: 0 auto;
}

.navHeaderBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.titleGrey {
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: 500;
  margin-top: 32px;
}

.titleBlue {
  margin-left: 7px;
}

.text {
  font-size: 15px;
  margin-top: 10px;
  max-width: 855px;
  line-height: 150%;
  font-weight: 500;
}

.title {
  margin-bottom: 9px;
  font-size: 25px;
  font-weight: 500;
}

.subTitle {
  font-size: 16px;
  max-width: 574px;
  font-weight: 500;
  line-height: 150%;
}

.btnBox button {
  padding: 0 20px;
}

.btn {
  margin-bottom: 10px;
}

.btnContinue {
  width: 254px;
  margin-top: 60px;
}

.instruction {
  box-sizing: border-box;
  width: 80%;
  margin-top: 10px;
  background-color: #f6f6f6;
  padding: 5px 5px;
  border-radius: 10px;
}

.passImage {
  width: 100%;
}

.inner_li {
  margin: 14px 24px;
  list-style: disc;
}

@media screen and (min-width:1200px) {}

@media screen and (min-width:992px) and (max-width:1199px) {
  .box {
    width: 80%;
  }
}

@media screen and (min-width:768px) and (max-width:991px) {
  .box {
    width: 100%;
    border-radius: 0px;
    margin-top: 230px;
    min-width: 320px;
  }

  .btnBox button {
    padding: 0;
  }

  .flexBox {
    flex-direction: column;
    align-items: center;
  }

  .boxLeft {
    margin-right: 0;
    width: 68%;
  }

  .infoBox {
    box-sizing: border-box;
    width: 100%;
  }

  .boxRight {
    box-sizing: border-box;
    width: 90%;
    order: -1;
  }

  .btn button {
    width: 100%;
  }
}

@media screen and (max-width:767px) {
  .box {
    box-sizing: border-box;
    width: 100%;
    border-radius: 0px;
    margin-top: 44px;
    min-width: 320px;
    padding: 10px;

  }

  .flexBox {
    flex-direction: column;
    align-items: center;
  }

  .boxLeft {
    margin-right: 0;
    width: 90%;
  }

  .infoBox {
    box-sizing: border-box;
    width: 100%;
  }

  .btnBox button {
    padding: 0;
  }

  .btn button {
    width: 100%;
  }

  .boxRight {
    box-sizing: border-box;
    width: 90%;
    order: -1;
  }

  .instruction {
    width: 100%;
  }

  .innerInfoBox {
    padding: 10px;
  }
}