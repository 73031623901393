* {
  margin: 0;
  padding: 0;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}

.box {
  width: 82%;
  margin-top: 200px;
  border-radius: 20px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
}

.box__subHeader {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 20px 20px 0 0;
  height: 103px;
}

.box__circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 45px;
  margin-right: 17px;
}

.box__title {
  font-size: 35px;
  font-weight: 500;
}

.flexBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  align-items: center;
  padding-bottom: 40px;
}

.radiusBox {
  width: 98%;
  border-radius: 20px;
  /* overflow: hidden; */
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
}

.tableHeader {
  width: 100%;
  height: 74px;
  border-radius: 20px 20px 0px 0px;
}

.tableHeader__item {
  height: 74px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 10px;
  width: 12.5%;
}
.tableHeader__item:first-child {
  border-radius: 20px 0px 0px 0px;
}

.tableHeader__item:last-child {
  border-radius: 0px 20px 0px 0px;
}

.table {
  border-radius: 20px;
  width: 100%;
  text-align: center;
  border-collapse: separate;
}

.table tr th {
  background: var(--maincolor);
  text-align: left;
}

.dataLine {
  height: 74px;
  background-color: #fff;
}

.dataLine td {
  height: 74px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid rgb(230, 230, 230);
  text-align: left;
  padding: 0 10px;
}
.firstTh {
  width: 300px;
}
.editBtn {
  width: 108px;
  height: 25px;
  border-radius: 10px;
  border: 1px solid var(--darkGrey);
  outline-style: none;
  cursor: pointer;
}
.passStatus {
  border-radius: 5px;
  padding: 5px 10px;
}
.grey {
  background-color: #c4c4c4;
  color: white;
}
.green {
  background-color: #b4e7b6;
  color: #404640;
}
.pink {
  background-color: #eeb6b6;
  color: #770b0b;
}
.orange {
  background-color: #e9a41e;
  color: #784f00;
}
.red {
  background-color: #b90404;
  color: white;
}
.btnEdit {
  text-align: center;
  min-width: 70px;
  border-bottom: 1px solid rgb(230, 230, 230);
}
.btnEdit button {
  border: 1px solid rgb(181, 181, 181);
  padding: 3px 30px;
  border-radius: 10px;
  cursor: pointer;
  outline-style: none;
  background-color: #fff;
}

.btnEdit button:hover {
  border: 1px solid var(--mainColor);
  padding: 3px 30px;
  border-radius: 10px;
  cursor: pointer;
  outline-style: none;
  background-color: var(--mainColor);
  color: white;
  transition: 0.4s;
  box-shadow: 0px 0px 4px var(--mainColor);
}
.statusTd {
  min-width: 130px;
}

/* .rentalUnitForm {
    width: 100%;
    display: flex;
    justify-content: center;
}
.rentalUnitInnerBox {
    width: 40%;
    margin: 0px 10px;
    margin-bottom: 20px;
}
.btnBox {
    display: flex;
    justify-content: center;
}
.btn {
    width: 254px;
    margin-top: 20px;
    margin-bottom: 40px;
    
}
.alertBox {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #fff;
} */


.grid_box {
  margin: 40px;
}

@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .box {
    margin-top: 230px;
  }

  .box {
    width: 98%;
  }

  .rentalUnitInnerBox {
    width: 50%;
  }
  .grid_box {
    margin: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .box {
    width: 100%;
    margin-top: 230px;
    border-radius: 0px;
  }

  .box__subHeader {
    border-radius: 0;
    height: 103px;
  }
  .table {
    border-radius: 0px;
    min-width: 1300px;
    text-align: center;
    border-collapse: separate;
  }
  .radiusBox {
    width: 98%;
    border-radius: 0px;
    overflow: auto;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .tableHeader__item:first-child {
    border-radius: 0px 0px 0px 0px;
  }

  .tableHeader__item:last-child {
    border-radius: 0px 0px 0px 0px;
  }

  /* .btn {
        width: 200px;
        display: flex;
        justify-content: center;
    }

    .rentalUnitInnerBox {
        width: 70%;
    } */
  .grid_box {
    margin: 10px;
  }
}

@media screen and (max-width: 767px) {
  .box {
    width: 100%;
    margin-top: 44px;
    border-radius: 0px;
  }

  .box__subHeader {
    border-radius: 0;
    height: 103px;
  }

  .box__innerBox {
    width: 100%;
    border-radius: 0px;
  }

  .box__circle {
    width: 16px;
    height: 16px;
    margin-left: 20px;
  }

  .box__title {
    font-size: 20px;
    font-weight: 500;
  }

  .flexBox {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
    padding-bottom: 40px;
  }
  .table {
    border-radius: 0px;
    min-width: 1200px;
    text-align: center;
    border-collapse: separate;
  }
  .radiusBox {
    width: 100%;
    border-radius: 0px;
    overflow: auto;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .tableHeader__item:first-child {
    border-radius: 0px 0px 0px 0px;
  }

  .tableHeader__item:last-child {
    border-radius: 0px 0px 0px 0px;
  }

  /* .rentalUnitForm {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .rentalUnitInnerBox {
        width: 100%;
        margin: 0px 0px;
        margin-bottom: 20px;
    }

    .btn {
        width: 200px;
        display: flex;
        justify-content: center;
    } */
  .grid_box {
    margin: 0px;
  }
}
