* {
    margin: 0;
    padding: 0;
  }
  
  .detail {
   margin:10px;
   width:50%
   }
   .passTypes{
    margin-top: 10px;
   }
   .alertPic svg {
    width: 30px;
    height: 30px;
  }
  .heading {
      font-family: Roboto !important;
      font-size: 18px !important;
      font-weight: 600 !important;
      line-height: 18.75px !important;
      text-align: left;
      color: #9C9C9C;

  }
  .subHeading {
    font-family: Roboto !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 16.41px !important;
    text-align: left;
    color: #4F4F4F;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
.actionBtn {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: right;
    margin-left:10px !important;
    text-decoration: underline;
}
.passHeading {
    font-family: Inter !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 14.52px !important;
    text-align: left;
}
.payHeading {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700 !important;
    line-height: 19.36px;
    text-align: left;
}
.payText {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: left;
    margin-top: 10px !important;

}
.passTypes {
    width: Fill (598px)px;
    height: Hug (51px)px;
    padding: 16px 0px 0px 0px;
    gap: 16px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    border: 1px solid #EFEFEF;
    background: #FDFDFD;

}

.dialogHeading{
    font-size: 24px !important;
    font-weight: 600 !important;
    margin-bottom: 10px !important;
}
.passLabel {
    font-family: Inter !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16.94px !important;
    text-align: left !important;
    width: 511px !important;
height: 17px !important;
gap: 0px !important;
opacity: 0px !important;


}

.passCapacity {
    font-family: Inter !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 19.36px !important;
    text-align: right !important;
    width: 45px;
height: 19px;
gap: 0px;
opacity: 0px;


}
  @media screen and (min-width: 1200px) {
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
  }
  @media screen and (max-width: 767px) {
  }
  