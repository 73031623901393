.container {
  position: absolute;
  background-color: #f2f2f2;
  min-height: 100%;
  width: 100%;
  left: '0';
  bottom: '0';
  box-sizing: border-box;
  padding-bottom: 400px;
}
/* ::-webkit-scrollbar {width:10px;height:10px;position:absolute;}
::-webkit-scrollbar-thumb {background-color:rgb(181, 181, 181); border-radius: 10px;box-shadow : inset 0 0 5px rgba(0, 0, 0, 0.2);}
::-webkit-scrollbar-thumb:hover {background-color:rgb(150, 150, 150); border-radius: 10px;box-shadow : inset 0 0 5px rgba(0, 0, 0, 0.2);}
::-webkit-scrollbar-track {background-color:#ddd; box-shadow : inset 0 0 5px rgba(0, 0, 0, 0.2);} */

@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .container {
    position: absolute;
    background-color: #f2f2f2;
    min-height: 100%;
    width: 100%;
    left: '0';
    bottom: '0';
    box-sizing: border-box;
    padding-bottom: 80px;
  }
}
