.a {
  color: red;
}
.innerBox {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-bottom: 20px;
}
.tabBox {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
.alertPic{
  display: flex;
  align-items: center;
  justify-content: center;
  color:  var(--mainColor);
}
.tab {
  position: relative;
  flex: 0.5;
  border: none;
  border-radius: 20px 20px 0 0;
  height: 58px;
  outline-style: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tabLine {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 12px;
  background-color: var(--lightGrey);
}
.tabLineActive {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 12px;
  background-color: var(--sucessColor);
}
.tab a {
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active {
  color: var(--sucessColor);
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: 500;
}
.inactive {
  color: var(--darkGrey);
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: 500;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .innerBox {
    border-radius: 0px;
  }
}
@media screen and (max-width: 767px) {
  .innerBox {
    border-radius: 0px;
  }
  .active {
    font-size: 12px;
  }
  .inactive {
    font-size: 12px;
  }
}
