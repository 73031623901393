.subHeader {
  display: flex;
  align-items: center;
  height: 103px;
  border-radius: 0 20px 0 0;
}
h2 {
  display: block;
  font-size: 19px;
  font-weight: bold; 
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: initial;
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 45px;
  margin-right: 17px;
}
.title {
  font-size: 35px;
  font-weight: 500;
}

.inline_label {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
}

.inline_label label:first-child {
  order: 1;
}

.inline_label label:nth-child(2) {
  float: left;
  margin-right: 10px;
}

.form {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 94px;
  padding-bottom: 108px;
}
.innerForm {
  width: 56%;
}
.inputBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  
}
.inputShort {
  width: 48%;
}
.inputLong {
  margin-bottom: 25px;
}
.inputFullLength{
  width: 100%;
}
.alert {
  font-size: 14px;
  text-align: center;
  margin-left: 8px;
}
.alertBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.saveBtn {
  width: 40%;
}
.btnBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 43px;
}
.searchSelector {
  width: 100%;
  font-size: 18px;
  color: var(--mediumGrey);
}

.searchSelector input {
  display: flex;
  height: 32px;
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--lightGrey);
  outline-style: none;
  font-size: 16px;
}

.searchSelector p {
  color: var(--mediumGrey);
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .innerForm {
    width: 80%;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .innerForm {
    width: 80%;
  }
  .subHeader {
    border-radius: 0;
  }
  .saveBtn {
    width: 200px;
  }
}
@media screen and (max-width: 767px) {
  .circle {
    width: 16px;
    height: 16px;
    margin-left: 20px;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
  }
  .subHeader {
    border-radius: 0;
  }
  .form {
    width: 100%;
    margin-top: 30px;
  }
  .innerForm {
    width: 80%;
  }
  .inputBox {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .inputShort {
    width: 100%;
    margin-bottom: 18px;
  }
  .inputLong {
    margin-bottom: 18px;
  }
  .saveBtn {
    width: 200px;
  }
}
