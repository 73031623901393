* {
  margin: 0;
  padding: 0;
}
.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}
.box {
  display: flex;
  flex: 0.96;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 200px;
  /* height: fit-content; */
  /* align-items: stretch; */

}

.box__innerBox {
  margin: 10px 10px;
  flex: 0.2;
  min-width: 230px;
  /* height: 100%; */
  /* flex-grow: 1; */
}
.displayNone {
  display: none;
}
.cardOutstand {
  background-color: #fff;
  border: 1px solid var(--mainColor);
  box-shadow: 0px 0px 14px 0px var(--mainColor);
}
.cardOutstand svg {
  fill: var(--mainColor);
  stroke: var(--mainColor);
}

@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .box {
    flex-wrap: wrap;
    margin-top: 200px;
  }
  .box__innerBox {
    margin: 10px 10px;
  }
  .box__innerBox {
    flex: 0.1;
  }
}

@media screen and (max-width: 767px) {
  .box {
    flex-direction: column;
    min-width: 320px;
    flex: 0.6;
    margin-bottom: 20px;
    margin-top: 64px;
  }
  .box__innerBox {
    margin-top: 10px;
  }

  .box__innerBox {
    margin: 10px 30px;
  }
}
