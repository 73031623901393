.text {
  box-sizing: border-box;
  padding: 200px 200px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .text {
    box-sizing: border-box;
    padding: 100px 20px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
  }
}
