.gridStatusBox {
  margin-top: 9px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  font-weight: 700;
  align-items: center;
  height: 25px;
}
.radiusBox {
  margin-top: 10px;
  width: 100%;
  height: 60vh;
  border-radius: 0px;
  box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.08);
  /* overflow: scroll;
  scroll-behavior: smooth;
  overscroll-behavior: contain; */
  background-color: rgb(240, 239, 239);
}
.active {
  background-color: #15a021;
  color: white;
}
.readyToScan {
  background-color: #9ccc8b;
  color: white;
}
.incomplete {
  background-color: #c4c4c4;
  color: white;
}
.expired {
  background-color: #a01515;
  color: white;
}
.refunded {
  background-color: #e9a41e;
  color: white;
}
.incompleteRentalCar {
  background-color: var(--darkGrey);
  color: white;
}
.item {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 0px 10px;
  text-transform: capitalize;
}
.topLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btnExport {
  display: flex;
  justify-content: center;
  width: 170px;
  height: 36px;
  background: #ffffff;
  border: 1px solid var(--mainColor);
  color: var(--mainColor);
  font-weight: 500;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  align-items: center;
}
.btnExport:hover {
  background-color: var(--mainColor);
  color: white;
  transition: 0.4s;
}
.gridSearch {
  border-radius: 5px;
  height: 36px;
  background-color: #f5f5f5;
  border: 1px solid #c2c2c2;
  font-size: 14px;
  outline-style: none;
  line-height: 36px;
  width: 20%;
  min-width: 200px;
  padding: 10px;
}
.textSearch input {
  width: '25%';
  padding: '10px 20px';
}
.bulkSelectBox {
  padding: 8px 20px 8px 10px;
  border: none;
  appearance: none;
  font-size: 14px;
  font-weight: 700;
  outline-style: none;
  color: black;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
}
.bulkSelectBox svg {
  stroke: var(--mainColor);
}
.bulkSelectBox:hover {
  background-color: var(--mainColor);
  color: white;
}
.actionBtn {
 border: none;
 color: var(--mainColor);
 background-color: transparent;
 font-weight: 500;
 cursor: pointer;
 display: flex;
}
.actionBox {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 11px;
}
.actionBtn svg {
  margin-top: 1px;
  min-width: 14px;
  max-width: 14px;
  max-height: 16px;
  stroke: var(--mainColor);
}
.menuContainer {
  width: 240px;
  background-color: #F8F8F8;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid #CCCFD4;
}
.menuItem {
  height: 30px;
  border-bottom: 1px solid #dee2ea;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #333333;
  font-size: 14px;
}
.menuItem:hover {
  background-color: #dee2ea;
}
.itemText {
  margin-left: 16px;
}
.selectBox {
  margin: 0 5px;
  width: 77px;
  border-radius: 2px;
  border: 1px solid #bdbdbd;
  outline-style: none;
  cursor: pointer;
  padding: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  }
  .selectBox option {
  font-size: 14px;
  font-weight: 500;
  }
  .paginationBox {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  }
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .topLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
  }
  .btnExport {
    width: 130px;
    font-size: 14px;
  }
}
.box {
  display: flex;
  flex-direction: column;
}
.btnBox {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.btnShare {
  width:141px;
  height: 35px;
  background-color: var(--mainColor);
  color: white;
  border:none;
  border-radius: 5px;
  cursor: pointer;
}
.btnCancel {
  width:141px;
  height: 35px;
  background-color: white;
  color: var(--mainColor);
  border:none;
  border-radius: 5px;
  border:1px solid var(--mainColor);
  cursor: pointer;
}
.selectBox option {
  font-size: 14px;
  font-weight: 500;
}