
/* Tooltip css */
/* Tooltip css */
.instructionContainer{
  margin-top: 50px;
}
.tooltip {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url(../../assets/question-icon.svg);
  top:5px;
}
.instructionText{
  margin-top: 1rem;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 350px;
  background-color: white;
  color: black;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  top: 50%;
  left: 150%;
  border: 1px solid grey;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  font-size: 13px;
  line-height: 1.6;
  transform: translateY(-50%);
}

.tooltip .tooltiptext.tootltipImg{
  width: 680px;
}
.tooltipContainer{
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.instcutionImg{
  width: 680px;
  height: auto;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
  
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}