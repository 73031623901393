* {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
}

.cardBox {
    flex: 1;
}

.card {
    min-height: 220px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0px 1px 8px 0px rgb(0 0 0 / 16%);
    padding: 0 1px;
    padding-bottom: 30px;
}

.card__title {
    flex: 0.14 1;
    font-size: .95rem;
    font-weight: 600;
    margin-top: 20px;
}

.card__description {
    font-size: 14px;
    font-weight: 600;
    max-width: 270px;
    height: 60px;
    margin-top: 4px;
    line-height: 150%;
}

.card__btn {
    bottom: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
    flex: 0 0 auto;
    align-content: flex-end;
    align-items: flex-end;
    position: absolute;
}

.custom_card_btn {
    margin-top: 50px;
    display: block;
}

.card__btnInnerBox {
    display: flex;
    flex: 0.8;
}

.card > svg {
    margin-top: 20px;
    flex: 0.33;
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .card {
        height: 360px;
    }

    .card svg {
        margin-top: 5px;
        width: 90px;
    }

    .card__title {
        margin-top: 5px;
    }

    .card__description {
        height: 80px;
    }

    .card__btn {
        margin-top: 40px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .card {
        height: 340px;
    }

    .card__description {
        height: 80px;
    }

    .card__btn {
        margin-top: 20px;
    }

    .card__title {
        margin-top: 0px;
    }

    .card__description {
        font-size: 12px;
        max-width: 200px;
    }

    .card svg {
        margin-top: 20px;
        width: 80px;
    }
}

@media screen and (max-width: 767px) {
    .card {
        height: 320px;
        border-radius: 20px;
    }

    .card__btn {
        margin-top: 40px;
    }

    .card__description {
        font-size: 12px;
        max-width: 200px;
    }

    .card__title {
        margin-top: 5px;
    }

    .card svg {
        margin-top: 5px;
        width: 80px;
        height: 100px;
    }
}