.btnBox {
  position: relative;
}
.btnClose button {
  background-color: white;
}
.btnOpen button {
  background-color: var(--mainColor);
  color: white;
}
