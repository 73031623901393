.box {
  margin-bottom: 20px;
}
.table {
  min-width: 1400px;
}
table tr:last-child td:last-child {
  border-radius: 0px 0px 10px 0px;
}
table tr:last-child td:first-child {
  border-radius: 0px 0px 0px 10px;
}
.borderBox {
  border-radius: 0px 10px 10px 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  overflow: auto;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
}
.title {
  box-sizing: border-box;
  height: 36px;
  width: 332px;
  background-color: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px 5px 0px 0px;
  color: var(--superDarkGrey);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #333333;
}
.tableHeader {
  width: 100%;
  height: 74px;
}

.sheet {
  height: 73px;
}
.tHeaderItem {
  font-size: 15px;
  font-weight: 500;
  color: white;
  text-align: left;
  padding: 0px 10px;
  height: 74px;
}
.leftPadding {
  padding-left: 10px;
}
.longWidthEmail {
  width: 150px;
}
.longWidthRental {
  width: 200px;
}
.item {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 0px 10px;
}
.breakWord {
  word-wrap: break-word;
  word-break: break-all;
}
.btnAction {
  text-align: center;
  width: 3% !important;
  min-width: 70px;
  border-bottom: 1px solid rgb(230, 230, 230);
}
.btnAction button {
  padding: 3px 5px;
  border: 1px solid #898e93;
  border-radius: 10px;
  cursor: pointer;
  outline-style: none;
}
.btnReinvite {
  text-align: center;
  width: 3% !important;
  min-width: 70px;
  border-bottom: 1px solid rgb(230, 230, 230);
}
.btnReinvite button {
  background-color: white;
  padding: 3px 5px;
  border: 1px solid #898e93;
  border-radius: 10px;
  cursor: pointer;
  outline-style: none;
}
.btnReinvite button:hover {
  background-color: var(--mainColor);
}
.btnDel {
  text-align: center;
  width: 3% !important;
  min-width: 70px;
  border-bottom: 1px solid rgb(230, 230, 230);
}
.btnDel button {
  background-color: #e1a3a3;
  padding: 3px 5px;
  border: 1px solid #821717;
  color: #ad1d1d;
  border-radius: 10px;
  cursor: pointer;
  outline-style: none;
}
.select {
  background-color: #eeeeee;
  width: 100%;
  height: 37px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline-style: none;
}
.statusBox {
  padding: 5px 10px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  font-weight: 600;
  text-align: center;
}
.active {
  background-color: #15a021;
  color: white;
}
.readyToScan {
  background-color: #9ccc8b;
  color: white;
}
.incomplete {
  background-color: #c4c4c4;
  color: white;
}
.expired {
  background-color: #a01515;
  color: white;
}
.refunded {
  background-color: #e9a41e;
  color: white;
}
.incompleteRentalCar {
  background-color: var(--darkGrey);
  color: white;
}
.actionsBtnItems {
  position: absolute;
  top: 30px;
  left: -48px;
  z-index: 1;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--lightGrey);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
}
.actionsBtnItems a button {
  width: 120px;
  border-radius: 0;
  border: none;
  border-bottom: 1px dashed var(--lightGrey);
  background-color: #fff;
}
.actionsBtnItems a button:hover {
  background-color: var(--mainColor);
  color: white;
  transition: 0.4s;
}
.actionsBtnItems a:last-child button {
  border: none;
}
.actionsBtnItemsUpper {
  position: absolute;
  top: -85px;
  left: -48px;
  z-index: 1;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--lightGrey);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
}
.actionsBtnItemsUpper a button {
  width: 120px;
  border-radius: 0;
  border: none;
  border-bottom: 1px dashed var(--lightGrey);
  background-color: #fff;
}
.actionsBtnItemsUpper a button:hover {
  background-color: var(--mainColor);
  color: white;
  transition: 0.4s;
}
.actionsBtnItemsUpper a:last-child button {
  border: none;
}
.paginationGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  background-color: #f3f3f3;
  padding: 5px;
  border-radius: 10px;
}
.paginationGroup input {
  width: 2.5rem;
  outline: none;
  border-radius: 7px;
  border-style: solid;
  border-color: var(--lightGrey);
  appearance: textfield;
  -moz-appearance: textfield;
}
.paginationGroup input::-webkit-outer-spin-button,
.paginationGroup input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* .pageNumGroup {
  justify-self: center;
} */
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .title {
    font-size: 14px;
  }
}
